import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import attachmentsReducer from '../features/score/attachmentsSlice';
import linesReducer from '../features/score/linesSlice';
import interfaceReducer from '../features/score/interfaceSlice';
import lineEditorReducer from '../features/score/lineEditorSlice';
import scoreReducer from '../features/score/scoreSlice';
import usersReducer from '../features/score/userSlice';
import translationsReducers from '../features/score/translationsSlice';
import ScoreListReducer from '../features/score/scoreListSlice';
import MyScoreListReducer from '../features/score/myScoreListSlice';
import organonReducer from '../features/organon/organonSlice';
import { apiSlice } from '../features/api/apiSlice';
import { putScore, fetchScoreById } from '../features/score/scoreSlice';
import { moveLinePreview, moveLineCancel, replaceAttachmentId } from '../features/score/linesSlice';
import undoable, { excludeAction } from 'redux-undo';
import { middleware as scoreInterfaceMiddleware } from '../features/score/interfaceSlice';
import playerReducer from '../features/score/playerSlice';
import { middleware as playerMiddleware } from '../features/score/playerSlice';
import { UNDOABLE_EDITOR_REDO, UNDOABLE_PLAYER_REDO, UNDOABLE_EDITOR_RESET, UNDOABLE_PLAYER_RESET, UNDOABLE_EDITOR_UNDO, UNDOABLE_PLAYER_UNDO,  } from '../features/score/constants';

console.log(fetchScoreById.fulfilled, fetchScoreById.fulfilled.type)

export const store = configureStore({
  reducer: {
    editor: undoable(combineReducers({
      score: scoreReducer,
      lines: linesReducer,
    }), {
      undoType: UNDOABLE_EDITOR_UNDO,
      redoType: UNDOABLE_EDITOR_REDO,
      initTypes: [ UNDOABLE_EDITOR_RESET, fetchScoreById.pending.type],
      ignoreInitialState: true,
      filter: excludeAction([
        putScore.pending.type,
        putScore.rejected.type,
        putScore.fulfilled.type,
        fetchScoreById.rejected.type,
        fetchScoreById.fulfilled.type,
        moveLinePreview.type,
        moveLineCancel.type,
        replaceAttachmentId.type
      ])
    }),
    attachments: attachmentsReducer,
    scoreList: ScoreListReducer,
    myScoreList: MyScoreListReducer,
    lineEditor: lineEditorReducer,
    interface: interfaceReducer,
    organon: organonReducer,
    player: undoable(playerReducer, {
      undoType: UNDOABLE_PLAYER_UNDO,
      redoType: UNDOABLE_PLAYER_REDO,
      initTypes: [UNDOABLE_PLAYER_RESET],
      ignoreInitialState: true
    }),
    translations: translationsReducers,
    users: usersReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware()
    .concat(apiSlice.middleware)
    .concat([ playerMiddleware ])
    .concat([ scoreInterfaceMiddleware ])
});
