/*
  var formData = new FormData();
  formData.append('title', attachment.get('title'));
  if (attachment.get('attachment')) {
      formData.append('attachment', attachment.get('attachment'));
  }
  else {
      formData.append('url', attachment.get('url'));
  }
  $.ajax({
      type: "POST",
      url: '/api/attachments/',
      enctype: 'multipart/form-data',
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: _.bind(function (data) {
          attachment.set(data);
          success();
      }, this),
      error: error,
      dataType: 'json'
  });
*/

import { dataURItoBlob } from "../../utils/file";
import { getCSRF } from "../../utils/crsf";
import { APIBaseUrl } from "../organon/settings";

export function uploadAttachment (attachment) {
  const promise = new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('title', attachment.title);
    formData.append('attachment', dataURItoBlob(attachment.url), attachment.filename);
    
    // // Simulate failure
    // reject();
    // return;

    fetch(
      `${ APIBaseUrl }/api/attachments/`,
      {
        'method': 'POST',
        'credentials': 'include',
        'body': formData,
        'headers': {
          'X-CSRFToken': getCSRF()
        }
      })
      .then(r => {
        r.json()
          .then(data => resolve(data))
          .catch(reject)
      })
      .catch(reject)
  });

  return promise;
}

export function duplicateAttachment (attachmentId) {
  const promise = new Promise((resolve, reject) => {
    fetch(
      `${ APIBaseUrl }/api/attachments/${ attachmentId }/duplicate/`,
      {
        'method': 'POST',
        'credentials': 'include',
        'headers': {
          'X-CSRFToken': getCSRF()
        }
      })
      .then(r => {
        r.json()
          .then(data => resolve(data))
          .catch(reject)
      })
      .catch(reject)
  });

  return promise;
}

export default { uploadAttachment, duplicateAttachment }