import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchScoreById } from './scoreSlice';
import { FETCH_STATUSES, FETCH_STATUS_FAILED, FETCH_STATUS_PENDING } from './constants'
import attachmentAPI from './attachmentApi';
import { newAttachment } from '../../utils/attachment';

/**
 * 
 *  attachment can be:

    @FIXME simplify? Explicitely add mode to the attachment object?
    Possibly in parsing of API

    In two modes:
    - file mode → if synced: mime, if unsynced: mime & *data*url
    - url → synced & no mime, unsynced & no file & url

    Have two states
    - synced
    - unsynced

    If a attachment is unsynced but the file is set the attachment is in file mode.
    Show the file loaded indication.

    If an attachment is synced, type should be set. If it's not, it's in url mode. Show URL.

 */

/**
 * Attachment shape
 * {
 *   id: string,
 *   url: string, # Location of the attachment
 *   title: string, # Title / name of the attachment
 *   type: string, # Mimetype
 *   file: null | File # Blob, only set on creation of attachment
 *   state: 'failed' | 'uploaded' | 'pending'
 * }
 */

const attachmentsAdapter = createEntityAdapter();

const adapterSelectors = attachmentsAdapter.getSelectors();

const reducerHelpers = {}

const localSelectors = {}

const adaptedSelector = (selector) => (state, ...args) => selector(state.attachments, ...args)

export const uploadAttachment = createAsyncThunk(
  'attachments/uploadAttachment',
  async (attachmentId, { getState, dispatch, rejectWithValue }) => {
    const attachment = selectAttachment(getState(), attachmentId);
    dispatch(updateAttachment({ id: attachmentId, changes: { state: FETCH_STATUS_PENDING } }));
    return attachmentAPI.uploadAttachment(attachment)
      .then((data) => {
        const newAttachment = {
          id: data.id,
          title: data.title,
          url: data.attachment,
          attachment: data.attachment,
          type: attachment.type 
        };
        
        // Insert new attachment, replace old entities
        dispatch(replaceAttachment({ newAttachment: newAttachment, oldAttachmentId: attachmentId }));
        // dispatch(addAttachment(newAttachment));
        // dispatch(removeAttachment(attachmentId));
        return newAttachment.id;
      })
      .catch((e) => {
        dispatch(updateAttachment({ id: attachmentId, changes: { state: FETCH_STATUS_FAILED } }));
        return rejectWithValue({
          id: attachmentId,
          error: e
        });
      });
  }
)

export const duplicateAttachment = createAsyncThunk(
  'attachment/duplicateAttachment',
  async (attachmentId, { getState, dispatch, rejectWithValue  }) => {
    const attachment = selectAttachment(getState(), attachmentId);
    // dispatch(duplicateAttachment({ id: attachmentId, changes: { state: FETCH_STATUS_PENDING } }));
    return attachmentAPI.duplicateAttachment(attachmentId)
      .then((data) => {
        const newAttachment = {
          id: data.id,
          title: data.title,
          url: data.attachment,
          attachment: data.attachment,
          type: attachment.type // Type is not stored on the attachment API.
        };
        
        // Insert new attachment
        dispatch(addAttachment(newAttachment));
        return newAttachment.id;
      })
      .catch((e) => {
        // dispatch(duplicateAttachment({ id: attachmentId, changes: { state: FETCH_STATUS_FAILED } }));
        return rejectWithValue({
          id: attachmentId,
          error: e
        });
      });
  }
)

export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState: attachmentsAdapter.getInitialState(),
  reducers: {
    addAttachment: (state, action) => attachmentsAdapter.addOne(state, action.payload),
    removeAttachment: (state, action) => attachmentsAdapter.removeOne(state, action.payload),
    setAttachment: (state, action) => attachmentsAdapter.setOne(state, action.payload),
    updateAttachment: (state, action) => attachmentsAdapter.updateOne(state, action.payload),
    replaceAttachment: (state, action) => {
      attachmentsAdapter.addOne(state, action.payload.newAttachment);
      attachmentsAdapter.removeOne(state, action.payload.oldAttachmentId);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScoreById.fulfilled, (state, action) => {
        attachmentsAdapter.addMany(state, Object.values(action.payload.lines).flatMap((line) => line.attachments))
      })
  }
})

export const selectAttachment = attachmentsAdapter.getSelectors((state) => state.attachments).selectById;

export const selectManyAttachments = (state, ids) => {
  return ids.map((id) => state.attachments.entities[id]);
};

export const selectAttachmentsIdsToUpload = (state) => {
  return state.attachments.ids.filter((id) => state.attachments.entities[id].state == FETCH_STATUSES.IDLE 
    || state.attachments.entities[id].state == FETCH_STATUSES.FAILED);
}

export const { addAttachment, replaceAttachment, removeAttachment, setAttachment, updateAttachment   } = attachmentsSlice.actions

export default attachmentsSlice.reducer;